import React from 'react'
import Layout from '../components/layout'
import FloatingCart from '../components/floating-cart'
import ResponsiveView from '../responsive/view'
import Hero from '../components/hero'

const Company = () => {
    return (
        <Layout>
            <ResponsiveView render={isMobile => (
                <FloatingCart isMobile={isMobile} />
            )} />

            <ResponsiveView render={(isMobile) => (
                <Hero 
                    isMobile={isMobile}
                    page="company" 
                    title="Serving you since 2013. We started off operating mostly from home selling mobile computers and Motorola 
                    handheld devices for a few years. Then, we started doing computer repairs, working for the average person as well
                    as some big companies."
                    body=""
                />
            )} />

        </Layout>
    )
}

export default Company
